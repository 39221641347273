// @ts-ignore
import React from 'react';
import './App.css';
import AboutComponent from "./components/About";
import ClassesComponents from "./components/Classes";
import Footer from "./components/Footer";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ClassesDetailsComponents from "./components/ClassDetails";
import ViewPdf from "./components/ViewPdf";

const router = createBrowserRouter([{
    path: "/",
    element: <>
        <AboutComponent/>
        <ClassesComponents/>
        <Footer/>
    </>,
    errorElement: <><p>Hmmm, looks like you are lost</p></>
}, {
    path: "/classes/:id",
    element: <>
        <AboutComponent/>
        <ClassesDetailsComponents/>
        <Footer/>
    </>
}, {
    path: "/view",
    element: <ViewPdf/>
}
])

function App() {
    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
