import React from 'react';
import styles from './About.module.css'
import me from '../static/media/me.jpeg'

const AboutComponent: React.FC = () => {
    return (
        <div className={styles.hero}>
            <div className={styles.hero_image}>
                <img src={me} className={styles.avatar}/>
            </div>
            <div className={styles.introduction}>
                <p className={styles.name}>Vivek Raghav</p>
                <p className={styles.subtitle}>Founder @ Padhai-Poori</p>
                <p className={styles.body}>Welcome to Padhai Poori Academy! I'm Vivek, an engineering undergrad turned educator, specializing in Math and Chemistry for Class 11 and 12. At Padhai Poori, we're on a mission to revolutionize education through critical thinking, problem-solving, and a love for learning. Join us to unlock your full potential and succeed together!</p>
            </div>
        </div>
    );
};

export default AboutComponent;