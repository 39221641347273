import React, {useEffect, useState} from 'react';
import styles from "./ClassesDetails.module.css";
import Loading from './Loading';
import {BASE_URL, currentPDF} from "../Constants";
import {useNavigate, useParams} from "react-router-dom";

interface ClassDetailsInfo {
    name: string;
    chapters: ChapterDetails[]
}

interface ChapterDetails {
    name: string;
    links: string[];
}

interface CollapsableProps {
    info: ClassDetailsInfo,
    onPdfClick: (string) => void,
}

function getLastWord(word: string): string {
    const parts = word.split('/'); // Split the string by '/'
    return parts[parts.length - 1]
}

function capitalizeFirstCharacter(str) {
    if (!str) return ''; // Return an empty string if str is not provided
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const CollapsibleContainer: React.FC<CollapsableProps> = (props) => {
    let collapsedStyle = styles.collapsed
    let expandedStyle = styles.expanded
    const [isCollapsed, setIsCollapse] = useState<boolean[]>(Array.from({
        length: props.info.chapters.length
    }, () => true))
    const toggleCollapse = (index) => {
        const updatedArray = [...isCollapsed];
        updatedArray[index] = !updatedArray[index];
        setIsCollapse(updatedArray);
    };

    return (
        <div>
            <p className={styles.subjectName}>{capitalizeFirstCharacter(props.info.name)}</p>
            {
                props.info.chapters.map((lecture, idx) => {
                    return <div className={`${styles.topContainer} `}>
                        <div key={lecture+idx.toString()} className={styles.cardHeader} onClick={()=>
                            toggleCollapse(idx)
                        }>
                            <h2 className={styles.fileName}>{lecture.name}</h2>
                            {isCollapsed.at(idx) ? <span>
                                ▼
                            </span> : <span>
                                ▲
                            </span>}
                        </div>
                        <div
                            className={`${styles.collapsibleContainer}  ${isCollapsed.at(idx) ? collapsedStyle : expandedStyle}`}>
                            {lecture.links.map((e) => <p className={styles.fileLink} onClick={() => {
                                props.onPdfClick(e)
                            }}>{getLastWord(e)}</p>)}
                        </div>
                    </div>
                })
            }
        </div>
    );
};

const ClassesDetailsComponents: React.FC = () => {
    const [data, setData] = useState<ClassDetailsInfo[]>([])
    const [loading, setLoading] = useState(true)
    let {id} = useParams()

    function openPDFInNewLink(fileUrl) {
        // open I frame
        // navigate(`/view`)
        // Open a new tab with the specified URL
        const newTab = window.open(BASE_URL + "/pdf" + fileUrl, '_blank');

        // Focus the new tab (optional)
        if (newTab) {
            newTab.focus();
        }
    }

    useEffect(() => {
        async function getSubjects() {
            try {
                setLoading(true)
                const response = await fetch(BASE_URL + "/subjects?id=" + id,)
                const result = await response.json()
                setLoading(false)
                setData(result)
                let resultsSize = parseInt(result.length)
                // @ts-ignore
                setIsCollapse(Array.from({
                    length: resultsSize
                }, () => false))
            } catch (e) {
                setLoading(false)
            }
        }

        getSubjects()
    }, [])

    return (
        <div className={styles.outer}>
            {loading ? <Loading/> : null}
            <div className={styles.container}>
                {data.map((item, idx) => {
                    return <CollapsibleContainer
                        key={item.name}
                        info={item}
                        onPdfClick={(e) => {
                            openPDFInNewLink(e)
                        }}
                    />
                })}
            </div>
        </div>

    );
};

export default ClassesDetailsComponents;