import React from 'react';
import styles from './Footer.module.css'
import linkedInLogo from '../static/media/LinkedIn.jpg'
import twitterLogo from '../static/media/twitter.png'
import githubLogo from '../static/media/github.png'
import facebook from '../static/media/facebook.png'
import youtube from '../static/media/youtube.png'

const FooterComponent: React.FC = () => {
    return (
        <div className={styles.container}>
            <p className={styles.connect}>Connect with us</p>
            <div className={styles.social_wrapper}>
                <a rel="noopener noreferrer" href="https://www.instagram.com/padhaipoori/" target="_blank">
                    <img alt=""
                         src={githubLogo}
                         className={`${styles.social_platform} ${styles.github_logo}`}/>
                </a>
                <a rel="noopener noreferrer" href="https://www.linkedin.com/in/vivek-raghav-795689176/" target="_blank">
                    <img
                        alt="" src={linkedInLogo} className={`${styles.social_platform} ${styles.linkedIn_logo}`}/>
                </a>
                <a rel="noopener noreferrer" href="https://www.facebook.com/padhaipoori" target="_blank">
                    <img
                        alt="" src={facebook} className={`${styles.social_platform} }`}/>
                </a>
                <a rel="noopener noreferrer" href="https://twitter.com/PadhaiPoori" target="_blank">
                    <img alt=""
                         src={twitterLogo}
                         className={`${styles.social_platform} }`}/>
                </a>
                <a rel="noopener noreferrer" href="https://www.youtube.com/@PadhaiPoori" target="_blank">
                    <img alt=""
                         src={youtube}
                         className={`${styles.social_platform} }`}/>
                </a>
            </div>
        </div>
    );
};

export default FooterComponent;