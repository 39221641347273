import React, {useEffect, useState} from 'react';
import styles from "./Classes.module.css";
import Loading from './Loading';
import {BASE_URL} from "../Constants";
import {Link, useNavigate} from "react-router-dom";

interface ClassInfo {
    id: number;
    name: string;
    subjects: string[];
    lectures: number
}

const ClassesComponents: React.FC = () => {
    const [data, setData] = useState<ClassInfo[]>([])
    const [loading, setLoading] = useState(true)
    let navigate = useNavigate()

    useEffect(() => {
        async function getClasses() {
            try {
                setLoading(true)
                const response = await fetch(BASE_URL + "/classes",)
                const result = await response.json()
                setLoading(false)
                setData(result)
            }catch (e) {
                setLoading(false)
            }
        }

        async function getSampleData(){
            fetch('https://jsonplaceholder.typicode.com/todos/1')
                .then(response => response.json())
                .then(json => console.log(json))
        }

        getSampleData()
        getClasses()
    }, [])

    return (
        <div className={styles.outer}>
            <p className={styles.heading}>Class Notes</p>
            {loading ? <Loading/> : null}
            <div className={styles.container}>
                {data.map((item) => {
                    return <div key={item.id} className={styles.class_card} onClick={()=>{
                        navigate(`/classes/${item.id}`)
                    }}>
                        <p className={styles.className}>{item.name}</p>
                        {item.subjects.map((e) => <p className={styles.subject}>{e}</p>)}
                    </div>
                })}
            </div>
        </div>

    );
};

export default ClassesComponents;