import React from 'react';
import styles from './About.module.css'

const ViewPdfComponent: React.FC = () => {
    return (
        <iframe
                className={styles.iframe} src={"https://www.africau.edu/images/default/sample.pdf"} />
    );
};

export default ViewPdfComponent;